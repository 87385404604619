import ProductLink from "./ProductLink";
import PropTypes from "prop-types";
import React from "react";
import ReskinnedSwiper from "./ReskinnedSwiper";
import { LATEST_DROP_SLUG } from "../core/urls";

function LatestDrop({ title, latestDrop }) {
  const href = latestDrop.brandSlug
    ? `/${latestDrop.brandSlug}/${LATEST_DROP_SLUG}`
    : `/${LATEST_DROP_SLUG}`;
  return (
    <ReskinnedSwiper
      href={href}
      heading={title}
      slidesPerView={3.3}
      items={latestDrop.products.map((product) => {
        return (
          <ProductLink
            key={product.slug}
            product={product}
            brandSlug={latestDrop.brandSlug ? latestDrop.brandSlug : null}
            showCondition
            showCategoryButtons
          />
        );
      })}
    />
  );
}

export default LatestDrop;

LatestDrop.propTypes = {
  title: PropTypes.string.isRequired,
  latestDrop: PropTypes.shape({
    products: PropTypes.array.isRequired,
  }).isRequired,
};
