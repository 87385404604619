import React, { useRef } from "react";

import Box from "./Box";
import Button from "./Button";
import ChevronIcon from "../assets/chevron.svg";
import Link from "next/link";
import PropTypes from "prop-types";
import Text from "./Text";
import { useHovered } from "../core/hooks";

function LargeLink({ text, href }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  return (
    <Link href={href} passHref>
      <Button
        ref={ref}
        as="a"
        display="inline-flex"
        alignItems="center"
        cursor="pointer"
      >
        <Box>
          <Text uppercase fontSize={[22, 22, 32, 32]} lineHeight={1}>
            {text}
          </Text>
          <Box
            height={["2px", null, "3px", null]}
            mt="1px"
            bg="black"
            width="100%"
            transition="opacity 200ms"
            opacity={hovered ? 0 : 1}
          />
        </Box>
        <Box
          pr={15}
          transition="transform 200ms ease-out"
          transform={
            hovered
              ? "rotate(180deg) translate3d(-4px, 0, 0)"
              : "rotate(180deg)"
          }
        >
          <ChevronIcon aria-label="Arrow" />
        </Box>
      </Button>
    </Link>
  );
}

export default LargeLink;

LargeLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
